import * as types from './types';

export default {
  [types.WEBINAR_STATUS_ATTENDED_LIVE]: 'Attended live',
  [types.WEBINAR_STATUS_ATTENDED_LIVE_AND_REPLY]: 'Attended live and reply',
  [types.WEBINAR_STATUS_ATTENDED_REPLY]: 'Reply',
  [types.WEBINAR_STATUS_CLICKED_CTA]: 'Clicked CTA',
  [types.WEBINAR_STATUS_MISSED]: 'Missed',
  [types.WEBINAR_STATUS_PURCHASED]: 'Purchased',
  [types.WEBINAR_STATUS_REGISTERED]: 'Registered',
  [types.WEBINAR_STATUS_SHOWN_CTA]: 'Shown CTA',
  [types.WEBINAR_STATUS_CLICKED_CTA_REPLY]: 'Clicked replay CTA',
  [types.WEBINAR_STATUS_SHOWN_CTA_REPLY]: 'Shown replay CTA',
};

<template>
  <ez-content class="contacts-membership">
    <ez-container>
      <ez-facts
        v-if="facts.length > 0"
        :limit="facts.length"
        :max="facts.length"
        :fluid="true"
        :items="facts"
      />
      <ez-box>
        <template slot="body">
          <ez-sub-header>
            <template slot="title">
              <div v-html="title"/>
            </template>
            <template slot="controls">
              <ez-button
                type="primary"
                icon="plus"
                @click="openAddCourse = true"
              >{{ $t('memberships.add-course-button' )}}</ez-button>
            </template>
          </ez-sub-header>

          <template v-if="loading && false">
            <div class="table-title mb-3 mt-3 ml-3 mr-3">
              <ez-preloader :value="true" :stop="false"/>
            </div>
          </template>

          <template>

            <app-stub-box
              v-if="displayMembers.length === 0 && !loading"
              :image="require('../assets/icons/academy.svg')"
              :title="$t('memberships.no-data-title')"
              :description="$t('memberships.no-data-description')"
            />

            <template v-for="member in displayMembers">
              <div class="table-title mt-4" :key="`header-${member.id}`">
                <ez-icon name="chalkboard-teacher" class="table-title__icon" size="20"/>
                <div class="table-title__text">{{ member.academy.name }}</div>
              </div>
              <ez-table
                class="table"
                v-if="member.academy.courses.length > 0"
                :key="`items-${member.id}`"
                :data="member.academy.courses"
                stripe
              >
                <div slot="empty">{{ $t("no-data") }}</div>
                <ez-table-column prop="date" :label="$t('memberships.fields.date')">
                  <template slot-scope="scope">{{scope.row.date | userTime}}</template>
                </ez-table-column>
                <ez-table-column prop="name" sortable :label="$t('memberships.fields.course')">
                  <template slot-scope="scope">
                    <ez-link :href="getCourseEditLink(scope.row)">{{scope.row.name}}</ez-link>
                  </template>
                </ez-table-column>
                <ez-table-column
                  prop="is-active"
                  sortable
                  :label="$t('memberships.fields.is-active')"
                >
                  <template slot-scope="scope">{{scope.row['is-active-label']}}</template>
                </ez-table-column>
                <ez-table-column prop="status" sortable :label="$t('memberships.fields.status')">
                  <template slot-scope="scope">{{scope.row.status}}</template>
                </ez-table-column>

                <ez-table-column fixed="right" :width="40">
                  <template slot="header" slot-scope="{}">
                    <div class="settings">
                      <ez-dropdown>
                        <ez-icon name="cog" size="20" color="#8095AA"/>
                        <ez-dropdown-menu slot="menu">
                          <ez-dropdown-item
                            icon="redo-alt"
                            @click="preChangeMemberPassword(member)"
                          >{{ $t('memberships.reset-password-button') }}</ez-dropdown-item>
                          <ez-dropdown-item
                            icon="trash"
                            type="danger"
                            iconColor="#EE5253"
                            @click="preDeleteMember(member)"
                          >{{ $t('delete-button') }}</ez-dropdown-item>
                        </ez-dropdown-menu>
                      </ez-dropdown>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div class="settings">
                      <ez-dropdown>
                        <ez-icon name="ellipsis-v" size="20" color="#8095AA"/>
                        <ez-dropdown-menu slot="menu">
                          <ez-dropdown-item
                            v-if="scope.row['is-active']"
                            icon="eye-slash"
                            @click="updateIsActive(scope.row['member-course-id'], false)"
                          >{{$t('disable-button')}}</ez-dropdown-item>
                          <ez-dropdown-item
                            v-if="!scope.row['is-active']"
                            icon="eye"
                            @click="updateIsActive(scope.row['member-course-id'], true)"
                          >{{$t('enable-button')}}</ez-dropdown-item>
                          <ez-dropdown-item
                            icon="trash"
                            type="danger"
                            iconColor="#EE5253"
                            @click="deleteMemberCourse(scope.row['member-course-id'])"
                          >{{$t('delete-button')}}</ez-dropdown-item>
                        </ez-dropdown-menu>
                      </ez-dropdown>
                    </div>
                  </template>
                </ez-table-column>
              </ez-table>
            </template>
          </template>

          <ez-dialog :open.sync="openAddCourse">
            <template slot="header">
              <ez-icon name="chalkboard-teacher" size="25" style="margin-right: 20px"/>
              {{ $t('memberships.add-course-dialog.header') }}
            </template>
            <ez-form>
              <ez-form-item>
                <ez-select
                  :placeholder="$t('memberships.add-course-dialog.academy-select-placeholder')"
                  :label="$t('memberships.add-course-dialog.academy-select-label')"
                  v-model="academy"
                  :options="availableAcademies"
                  item-label="name"
                  track-by="id"
                  @select="onAcademyChange"
                ></ez-select>
              </ez-form-item>
              <ez-form-item v-if="academyType === 'SNG'">
                <ez-select
                  :placeholder="$t('memberships.add-course-dialog.course-select-placeholder')"
                  :label="$t('memberships.add-course-dialog.course-select-label')"
                  v-model="selectedCourses"
                  :options="availableCourses"
                  :multiple="true"
                  :taggable="true"
                  item-label="name"
                  track-by="id"
                />
              </ez-form-item>
              <ez-form-item v-if="academyType === 'PKG'">
                <ez-select
                  :placeholder="$t('memberships.add-course-dialog.package-select-placeholder')"
                  :label="$t('memberships.add-course-dialog.package-select-label')"
                  v-model="selectedPackages"
                  :options="availablePackages"
                  :multiple="true"
                  :taggable="true"
                  item-label="name"
                  track-by="id"
                />
              </ez-form-item>
            </ez-form>

            <ez-box class="box--inner">
              <template slot="body" justify="between">
                <ez-button-group justify="between">
                  <div>{{ $t('memberships.add-course-dialog.is-notification-label') }}</div>
                  <ez-switch v-model="sendEmail" active-color="#00adab"/>
                </ez-button-group>
              </template>
            </ez-box>

            <template slot="footer">
              <ez-button-group justify="end">
                <ez-button
                  type="secondary"
                  @click="openAddCourse = false">
                  {{ $t('cancel-button') }}
                </ez-button>
                <ez-button
                  type="primary"
                  :disabled="!addFormIsValid"
                  @click="onAddCourses"
                  :preloader="isSync"
                >{{ $t('add-button') }}</ez-button>
              </ez-button-group>
            </template>
          </ez-dialog>
          <ez-dialog :open.sync="openResetPassword">
            <template slot="header">
              <ez-icon name="lock" size="25" style="margin-right: 20px"/>
              {{ $t('memberships.reset-password-dialog.header') }}
            </template>
            <ez-form>
              <ez-form-item>
                <ez-input
                  v-model="password"
                  :label="$t('memberships.reset-password-dialog.password-input-label')"
                  :placeholder="$t('memberships.reset-password-dialog.password-input-placeholder')"
                  icon="random"
                  :showIcon="true"
                  class="reset-icon"
                />
              </ez-form-item>
            </ez-form>

            <ez-box class="box--inner">
              <template slot="body">
                <ez-button-group justify="between">
                  <div>{{ $t('memberships.reset-password-dialog.is-notification-label') }}</div>
                  <ez-switch v-model="sendEmail" active-color="#00adab"/>
                </ez-button-group>
              </template>
            </ez-box>

            <template slot="footer">
              <ez-button-group justify="end">
                <ez-button
                  type="secondary"
                  @click="openResetPassword = false"
                >{{ $t('cancel-button') }}</ez-button>
                <ez-button
                  type="primary"
                  :disabled="!passwordIsValid"
                  @click="changeMemberPassword"
                  :preloader="isSync"
                >{{ $t('reset-button') }}</ez-button>
              </ez-button-group>
            </template>
          </ez-dialog>
        </template>
      </ez-box>
    </ez-container>
  </ez-content>
</template>

<script>
import { URL_MEMBER } from '@dmant/ez-env-common';
import { mapState, mapActions } from 'vuex';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import contactMixin from '@/mixins/contact';
import iconMonitor from '@/assets/icons/monitor.svg';
import iconStudent from '@/assets/icons/student.svg';
import userTime from '@/services/formatters/userTime';
import * as actions from '../store/academies/actions';
import * as getters from '../store/academies/getters';

const STORE_PREFIX = 'academies';

export default {
  name: 'AppMembership',
  mixins: [contactMixin],
  dependencies: ['$log'],
  filters: {
    userTime,
  },
  created() {
    this.getData();
    // load all membership stuff (academies, course, member, etc)
    this.loadAll().catch((err) => {
      this.$log(err);
    });
  },
  data() {
    return {
      openAddCourse: false,

      academy: null,
      title: this.$t('memberships.title'),
      pageTitle: this.$t('header.menuLinks.membership'),
      selectedCourses: [],
      selectedPackages: [],

      // selected member for password change / delete member dialogs
      selectedMember: null,
      // reset member password
      openResetPassword: false,
      sendEmail: true,
      password: '',
    };
  },
  watch: {
    academy() {
      this.selectedCourses = [];
      this.selectedPackages = [];
    },
  },
  methods: {
    loadAll() {
      const contactId = this.currentContactId;
      return this.$store.dispatch(
        `${STORE_PREFIX}/${actions.LOAD_ALL}`,
        contactId,
      );
    },

    ...mapActions('contacts_/membership', ['getData']),
    onChange({ limit }) {
      this.getData({ limit });
    },
    resetPassword() {
      this.openResetPassword = false;
    },

    // handle attach courses to contact
    async onAddCourses() {
      const data = {
        academy: get(this, 'academy.id', null),
        courses: this.selectedCourses.map((item) => item.id),
        packages: this.selectedPackages.map((item) => item.id),
        contactId: this.currentContactId,
        isNotification: true,
      };
      try {
        await this.$store.dispatch(
          `${STORE_PREFIX}/${actions.ATTACH_TO_COURSES}`,
          data,
        );
        this.openAddCourse = false;
        this.academy = null;
        this.selectedCourses = [];
      } catch (err) {
        this.$log(err);
      }
    },

    // handle academy change
    onAcademyChange() {
      this.selectedCourses = [];
      this.selectedPackages = [];
    },

    // handle update members-course is-active
    updateIsActive(memberCourseId, isActive) {
      this.$store.dispatch(
        `${STORE_PREFIX}/${actions.UPDATE_MEMBERS_COURSE_IS_ACTIVE}`,
        { memberCourseId, isActive, contactId: this.currentContactId },
      );
    },

    deleteMemberCourse(memberCourseId) {
      this.$store.dispatch(`${STORE_PREFIX}/${actions.DELETE_MEMBERS_COURSE}`, {
        memberCourseId,
        contactId: this.currentContactId,
      });
    },

    preDeleteMember(member) {
      this.deleteMember(member);
    },

    deleteMember(member) {
      this.$store.dispatch(`${STORE_PREFIX}/${actions.DELETE_MEMBER}`, {
        memberId: member.id,
        contactId: this.currentContactId,
      });
    },

    preChangeMemberPassword(member) {
      this.selectedMember = member;
      this.password = '';
      this.openResetPassword = true;
    },

    async changeMemberPassword() {
      if (this.selectedMember && this.passwordIsValid) {
        try {
          await this.$store.dispatch(
            `${STORE_PREFIX}/${actions.UPDATE_MEMBER_PASSWORD}`,
            {
              memberId: this.selectedMember.id,
              contactId: this.currentContactId,
              password: this.password,
              isNotification: this.sendEmail,
            },
          );
          this.openResetPassword = false;
        } catch (err) {
          this.$log(err);
        }
      }
    },

    getCourseEditLink(course) {
      if (course != null) {
        const academyId = course['academy-id'];
        const courseId = course['course-id'];
        const url = `${URL_MEMBER}/academy/${academyId}/courses/${courseId}/edit`;
        return url;
      }
      return null;
    },
  },
  computed: {
    ...mapState('contacts_/membership', ['data']),

    facts() {
      if (this.currentContact == null) {
        return [];
      }
      const totalLessons = get(
        this.currentContact,
        'attributes.total-lessons',
        0,
      );
      const completedLessons = get(
        this.currentContact,
        'attributes.completed-lessons',
        0,
      );
      const lessonsStatus = totalLessons === 0 && completedLessons === 0
        ? 'N/A'
        : `${completedLessons}/${totalLessons}`;
      return [
        {
          id: 1,
          header: get(this.currentContact, 'attributes.logins', 'N/A'),
          icon: iconMonitor,
          text: this.$t('memberships.widgets.number-of-logins'),
        },
        {
          id: 2,
          header: lessonsStatus,
          icon: iconStudent,
          text: this.$t('memberships.widgets.lessons-completed'),
        },
      ];
    },

    // academies
    academies() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.ACADEMIES}`];
    },

    isSync() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.LOADING}`];
    },

    loading() {
      // return false;
      return this.$store.getters[`${STORE_PREFIX}/${getters.LOADING}`];
    },

    availableAcademies() {
      return this.academies.map((item) => ({
        id: item.id,
        name: get(item, 'attributes.name', ''),
        type: get(item, 'attributes.type', 'SNG'),
        courses: get(item, 'relationships.courses', []).map((courseItem) => ({
          id: courseItem.id,
          name: get(courseItem, 'attributes.name'),
        })),
        packages: get(item, 'relationships.packages', []).map((packageItem) => ({
          id: packageItem.id,
          name: get(packageItem, 'attributes.name'),
        })),
      }));
    },

    academyType() {
      return this.academy == null ? null : this.academy.type;
    },

    addFormIsValid() {
      if (this.academyType === null) {
        return false;
      } if (
        this.academyType === 'SNG'
        && this.selectedCourses != null
        && this.selectedCourses.length > 0
      ) {
        return true;
      } if (
        this.academyType === 'PKG'
        && this.selectedPackages != null
        && this.selectedPackages.length > 0
      ) {
        return true;
      }
      return false;
    },

    // courses

    courses() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.COURSES}`];
    },

    membersCourses() {
      const items = this.$store.getters[
        `${STORE_PREFIX}/${getters.MEMBERS_COURSES}`
      ];
      return keyBy(items, 'relationships.courses[0].id');
    },

    availableCourses() {
      return get(this.academy, 'courses', []).filter((item) => (
        this.usedCourses.find((id) => item.id === id) == null
      ));
    },

    availablePackages() {
      return get(this.academy, 'packages', []).filter((item) => (
        this.usedPackages.find((id) => item.id === id) == null
      ));
    },

    usedCourses() {
      let result = [];
      // eslint-disable-next-line
      for (const member of this.displayMembers) {
        const items = get(member, 'academy.courses', []);
        result = [...result, ...items.map((item) => item['course-id'])];
      }
      return result;
    },

    usedPackages() {
      let result = [];
      // eslint-disable-next-line
      for (const member of this.displayMembers) {
        const items = get(member, 'academy.packages', []);
        result = [...result, ...items.map((item) => item['package-id'])];
      }
      return result;
    },

    // members

    members() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.MEMBERS}`];
    },

    // display data
    displayMembers() {
      return (this.members || []).map((member) => {
        const courses = get(member, 'relationships.courses', []);
        const packages = get(member, 'relationships.packages', []);
        return {
          id: member.id,
          academy: {
            id: get(member, 'relationships.academies[0].id'),
            name: get(member, 'relationships.academies[0].attributes.name'),
            courses: courses.map((course) => {
              const { id } = course;
              const memberCourse = this.membersCourses[id];
              return {
                'member-id': member.id,
                'course-id': id,
                name: get(course, 'attributes.name', ''),
                'academy-id': get(member, 'relationships.academies[0].id'),
                'member-course-id': get(memberCourse, 'id'),
                'is-active': get(memberCourse, 'attributes.is-active'),
                'is-active-label': get(
                  memberCourse,
                  'attributes.is-active',
                  false,
                )
                  ? 'Active'
                  : 'Not active',
                status: get(memberCourse, 'attributes.status'),
                date: get(memberCourse, 'attributes.updated-at'),
              };
            }),
            packages: packages.map((packageObj) => {
              const { id } = packageObj;
              return {
                'member-id': member.id,
                'package-id': id,
                name: get(packageObj, 'attributes.name', ''),
              };
            }),
            type: get(member, 'relationships.academies[0].attributes.type'),
          },
        };
      });
    },

    passwordIsValid() {
      return this.password && this.password !== '' && this.password.length >= 3;
    },
  },
};
</script>

import * as contactsGetters from '@/store/contacts/getters';

const CONTACTS_STORE_PREFIX = 'contacts';

export default {
  beforeMount() {
    this.initPageTitle(this.currentContact);
  },
  watch: {
    currentContact(newValue) {
      this.initPageTitle(newValue);
    },
  },
  methods: {
    initPageTitle(contact) {
      const data = [this.$t('app-public-title')];
      if (contact != null) {
        const { firstName = null, lastName = null, email } = contact;
        let displayName = email;
        if (firstName != null || lastName != null) {
          displayName = `${firstName || ''} ${lastName || ''}`.trim();
        }
        data.push(displayName);
      }
      data.push(this.pageTitle);
      window.document.title = data.join(' - ');
    },
  },
  computed: {
    // current contact and it's id
    currentContactId() {
      return this.$route.params.contactId;
    },
    currentContact() {
      const data = this.$store.getters[
        `${CONTACTS_STORE_PREFIX}/${contactsGetters.CURRENT_ITEM}`
      ];
      return data;
    },
    currentContactName() {
      if (!this.currentContact) { return ''; }
      const { firstName, lastName, email } = this.currentContact;
      return firstName || lastName
        ? `${firstName || ''} ${lastName || ''}`.trim()
        : email;
    },
  },
};

<template>
  <ez-container>
    <ez-content>
      <ez-facts
        v-if="facts.length > 0"
        :limit="facts.length"
        :max="facts.length"
        :fluid="true"
        :items="facts"
      />
      <ez-box>
        <template slot="body">
          <ez-sub-header>
            <template slot="title">
              <div v-html="title"></div>
            </template>
          </ez-sub-header>
          <template v-if="!loading">

            <app-stub-box
              v-if="displayItems.length === 0"
              :image="require('../assets/icons/webinar.svg')"
              :title="$t('webinars.no-data-title')"
              :description="$t('webinars.no-data-description')"
            />

            <ez-table class="table" v-if="displayItems.length > 0" :data="displayItems" stripe>
              <div slot="empty">{{ $t("no-data") }}</div>
              <ez-table-column prop="registrationDate" sortable :label="$t('webinars.fields.date')">
                <template slot-scope="scope">{{scope.row.createDate | userTime}}</template>
              </ez-table-column>
              <ez-table-column
                prop="webinarTitle"
                sortable
                :label="$t('webinars.fields.webinar-title')">
                <template slot-scope="scope">
                  <ez-link :href="getWebinarLink(scope.row)">{{scope.row.webinarTitle}}</ez-link>
                </template>
              </ez-table-column>
              <ez-table-column prop="status" sortable :label="$t('webinars.fields.status')">
                <template slot-scope="scope">{{scope.row.statusLabel}}</template>
              </ez-table-column>
            </ez-table>
            <ez-pagination
              v-if="items.length"
              :page="pageNumber"
              :page-count="totalPages"
              :page-range="pageRange"
              :limit="pageSize"
              :limit-options="limitOptions"
              @change="onPaginationChange"
            />
          </template>
        </template>
      </ez-box>
    </ez-content>
  </ez-container>
</template>

<script>
import { URL_WEBINAR } from '@dmant/ez-env-common';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import contactMixin from '@/mixins/contact';
import iconWebinarUser from '@/assets/icons/webinar-user.svg';
import iconWebinarPurchases from '@/assets/icons/webinar-purchases.svg';
import userTime from '@/services/formatters/userTime';
import * as getters from '../store/funnels/getters';
import * as actions from '../store/funnels/actions';
import labels from './webinars/labels';

const STORE_PREFIX = 'webinars';

export default {
  mixins: [contactMixin],
  filters: {
    userTime,
  },
  data() {
    return {
      page: 1,
      pageCount: 20,
      pageRange: 7,
      limit: 10,
      limitOptions: [10, 20, 30],
      title: this.$t('webinars.title'),
      pageTitle: this.$t('header.menuLinks.webinars'),
    };
  },
  computed: {
    ...mapGetters({
      items: `${STORE_PREFIX}/${getters.ITEMS}`,
      webinarData: `${STORE_PREFIX}/${getters.DATA}`,
    }),
    facts() {
      if (this.currentContact == null) {
        return [];
      }
      return [
        {
          id: 1,
          header: get(
            this.currentContact,
            'attributes.webinars-attended',
            'N/A',
          ),
          icon: iconWebinarUser,
          text: this.$t('webinars.widgets.webinars-attended'),
        },
        {
          id: 2,
          header: get(
            this.currentContact,
            'attributes.purchases-through-webinar',
            'N/A',
          ),
          icon: iconWebinarPurchases,
          text: this.$t('webinars.widgets.purchases-through-webinar'),
        },
      ];
    },

    subItems() {
      const includedItems = this.webinarData && this.webinarData.included
        ? this.webinarData.included
        : [];
      return includedItems.reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {});
    },
    displayItems() {
      return this.items.map((item) => {
        const { attributes } = item;

        const createDate = attributes['dt-create'];
        const { status } = attributes;
        const statusLabel = labels[status];
        const { id, name: webinarTitle } = this.getDependency(item) || {};

        // const webinarTitle = this.getDependencyTitle(item);
        // const id = attributes['id'];

        return {
          id,
          createDate,
          statusLabel,
          webinarTitle,
        };
      });
    },
    totalItems() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_ITEMS}`];
    },
    empty() {
      return this.totalItems === 0;
    },
    totalPages() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_PAGES}`];
    },
    loading() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.LOADING}`];
    },
    pageSize() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_SIZE}`];
    },
    pageNumber() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_NUMBER}`];
    },

    enabled() {
      return this.currentContact != null;
    },
  },

  created() {
    // this.getData({ limit: this.limit });
    this.loadItems();
  },
  methods: {
    // load data
    loadItems(params = {}) {
      const actualParams = {
        contactId: this.currentContactId,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...params,
      };
      this.$store.dispatch(
        `${STORE_PREFIX}/${actions.GET_ITEMS}`,
        actualParams,
      );
    },

    // sorting
    onSortChange(value) {
      const prop = value.prop == null ? 'name' : value.prop;
      const order = value.order == null ? 'ascending' : value.order;
      this.loadItems({ pageNumber: 1, sortProp: prop, sortOrder: order });
    },

    // pagination
    onPaginationChange({ limit, page }) {
      this.loadItems({ pageSize: limit, pageNumber: page });
    },

    getDependency(item) {
      const { relationships } = item;

      if (relationships) {
        const { webinar, academy } = relationships;
        if (webinar && webinar.data && webinar.data.id) {
          const obj = this.subItems[webinar.data.id];
          if (obj && obj.attributes) {
            return {
              id: obj.id,
              name: obj.attributes.name,
            };
          }
        } else if (academy && academy.data && academy.data.id) {
          const obj = this.subItems[academy.data.id];
          if (obj && obj.attributes) {
            return {
              id: obj.id,
              name: obj.attributes.name,
            };
          }
        }
      }

      return null;
    },

    getWebinarLink(webinar) {
      if (webinar && webinar.id != null && webinar.id !== '') {
        return `${URL_WEBINAR}/wizard/${webinar.id}/general`;
      }
      return null;
    },
  },
};
</script>

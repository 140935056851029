export const WEBINAR_STATUS_REGISTERED = 1;
export const WEBINAR_STATUS_MISSED = 2;
export const WEBINAR_STATUS_ATTENDED_REPLY = 3;
export const WEBINAR_STATUS_ATTENDED_LIVE = 4;
export const WEBINAR_STATUS_ATTENDED_LIVE_AND_REPLY = 5;
export const WEBINAR_STATUS_CLICKED_CTA = 6;
export const WEBINAR_STATUS_PURCHASED = 7;
export const WEBINAR_STATUS_SHOWN_CTA = 8;
export const WEBINAR_STATUS_CLICKED_CTA_REPLY = 9;
export const WEBINAR_STATUS_SHOWN_CTA_REPLY = 10;
